// import { useState } from "react";
// import emailjs from "emailjs-com";
import React from "react";

// const initialState = {
//   name: "",
//   email: "",
//   message: "",
// };
export const Contact = (props) => {
  // const [{ name, email, message }, setState] = useState(initialState);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setState((prevState) => ({ ...prevState, [name]: value }));
  // };
  // const clearState = () => setState({ ...initialState });

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log(name, email, message);

  //   {
  //     /* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */
  //   }

  //   emailjs
  //     .sendForm(
  //       "YOUR_SERVICE_ID",
  //       "YOUR_TEMPLATE_ID",
  //       e.target,
  //       "YOUR_PUBLIC_KEY"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //         clearState();
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  // };
  return (
    <div id="footer" style={{ backgroundColor: "#f8f9fa", padding: "40px 0" }}>
    <div className="container">
      {/* Contact Us Heading */}
      <h3 style={{ textAlign: "center", marginBottom: "30px" }}>Contact Us</h3>
  
      {/* Contact Information */}
      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          textAlign: "center",
          gap: "20px",
        }}
      >
        <div className="contact-item">
          <p>
            <span>
              <i className="fa fa-map-marker"></i> Address
            </span>
            <br />
            {props.data ? props.data.address : "loading"}
          </p>
        </div>
  
        <div className="contact-item">
          <p>
            <span>
              <i className="fa fa-phone"></i> Phone
            </span>
            <br />
            {props.data ? props.data.phone : "loading"}
          </p>
        </div>
  
        <div className="contact-item">
          <p>
            <span>
              <i className="fa fa-envelope-o"></i> Email
            </span>
            <br />
            <a href={`${"mailto:"}${props.data ? props.data.email : "loading"}`} className="no-underline">{props.data ? props.data.email : "loading"}</a>
          </p>
        </div>
      </div>
  
      {/* Footer Information */}
      <div className="text-center" style={{ marginTop: "40px" }}>
        <p>
          &copy; {new Date().getFullYear() } Kabaadia.com  </p>
      </div>
    </div>
  </div>
  
  
  
  );
};
